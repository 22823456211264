/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Font Families */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

ion-list.social-media {
    background: transparent;
    --background: transparent;
    ion-item {
        --background: transparent;
        display: inline-table;
    }
    ion-item:first-of-type {
        -webkit-padding-start: 0;
        --padding-start: 0;
    }
}

@media only screen and (min-width: 992px) {
    ion-header {
        display: none;
    }

    .section-light,
    .section-dark {
        padding: 0 5% !important
    }
}

.section-light {
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
}
.section-dark {
    background: var(--ion-color-secondary);
    color: var(--ion-color-secondary-contrast);
}


/** gradient backgrounds **/
.bkgrd-light {
    background-color: rgba(15, 21, 61, 0.05);
}
.bkgrd-medium {
    background-color: rgba(15, 21, 61, 0.1);
}
.bkgrd-hard {
    background-color: rgba(15, 21, 61, 0.15);
}

body.dark {
    background: var(--ion-color-secondary);

    .bkgrd-light {
        background-color: rgba(255, 255, 255, 0.05);
    }
    .bkgrd-medium {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .bkgrd-hard {
        background-color: rgba(255, 255, 255, 0.15);
    }
}
